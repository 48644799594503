import { Component, OnInit, HostListener } from '@angular/core';
import {Router} from '@angular/router';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.less']
})
export class HeaderNavComponent implements OnInit {

  constructor(
    private _router: Router, // 路由
    private _jumpPage: JumpPageService,
  ) { }

  public navArr: Array<any> = [
    {
      title: '首页',
      route: '/home',
    },
    {
      title: '关于我们',
      route: '/about',
      children: [
        {
          title: 'app.header-nav.childTitle1',
          anchors: 'companyIntro',
        },
        {
          title: 'app.header-nav.childTitle2',
          anchors: 'companyMission',
        },
        {
          title: 'app.header-nav.childTitle3',
          anchors: 'companyCulture',
        },
        {
          title: 'app.header-nav.childTitle4',
          anchors: 'companyFounder',
        },
        {
          title: 'app.header-nav.childTitle5',
          anchors: 'companyDevolop',
        },
        {
          title: 'app.header-nav.childTitle6',
          anchors: 'companyHonor',
        },
      ]
    },
    {
      title: 'app.header-nav.parentTitle2',
      children : [
        {
          title: 'app.header-nav.childTitle7',
          link: 'https://www.oatos.com/'
        },
        {
          title: 'app.header-nav.childTitle8',
          link: 'https://www.merchdna.com/'
        },
        {
          title: 'FILEDNA',
          link: 'https://www.filedna.tech/'
        },
        {
          title: 'app.header-nav.childTitle9',
          route: '/laboratory'
        },

      ]
    },
    {
      title: 'app.header-nav.parentTitle4',
      children: [
        {
          title: 'app.header-nav.childTitle10',
          link: 'https://www.oatos.com/news/company-news'
        },
        {
          title: 'app.header-nav.childTitle11',
          link: 'https://www.oatos.com/courses/henrycolumn'
        },
        {
          title: 'app.header-nav.childTitle12',
          link: 'https://www.oatos.com/news/trade-news'
        },
      ]
    },
    {
      title: '加入我们',
      route: '/careers',
    }
  ];

  // 是否显示为黑色页头
  public headerColor: boolean = false;

  ngOnInit(): void {
    this.changePos();
  }

  @HostListener('window:scroll', ['$event'])
  scrolling(event) {
    this.headerColor = window.pageYOffset > 5;
  }

  ngAfterViewChecked(): void {
    window.addEventListener('scroll', this.changePos);
  }

  private changePos(): void {
    let obj: Element = document.getElementById('qycloud-header-nav');
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 10) {
      obj && obj.classList.add('active');
    } else {
      obj && obj.classList.remove('active');
    }
  }

  public gotoPlace(subItem: string): void {
    let newPath: Array<string> = this._router.url.split('#');
    if (newPath[1]) {
      let id: string = subItem;
      this._jumpPage.goAnchor( '#' + id);
    }
    this._router.navigate( [`/about`], { fragment: subItem });
  }

}
