<app-header
  [title]="contactTitle | translate"
  [desc]="contactDesc | translate"
  [imgSrc]="contactImg"
></app-header>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div class="qycloud-contact-item1" *ngFor="let content1 of contactContent1">
      <div class="qycloud-item-title">
        {{content1.title | translate}}
      </div>

      <div class="qycloud-contact-item1-desc">
        {{content1.desc | translate}}
      </div>

      <div class="qycloud-contact-item1-list">
        <div class="qycloud-contact-item1-list-cell" *ngFor="let item of content1.children">
          <img [src]="item.imgUrl">
          <div>
            {{item.title | translate}}
          </div>
        </div>
      </div>

      <div class="qycloud-contact-item1-man">
        <div class="qycloud-contact-item1-man-content" *ngFor="let content2 of contactContent2" >
          <div class="qycloud-contact-item1-man-title">
            <!-- 客户服务 -->
            {{content2.title | translate}}
          </div>
          <ul>
            <li>
              <!-- 国际专线：+86-755-23358866 -->
              {{content2.desc[0] | translate}}
            </li>
            <li>
              <!-- 服务热线：400-086-1108 -->
              {{content2.desc[1] | translate}}
            </li>
            <li>
              <!-- 在线客服： -->
              {{content2.desc[2] | translate}}
              <a target="_blank" href="{{content2.link}}">
                <!-- 点击交谈 -->
                {{content2.btn | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div *ngFor="let content3 of contactContent3">
      <div class="qycloud-item-title">
        {{content3.title | translate}}
      </div>

      <div class="qycloud-item2-list">
        <div class="qycloud-item2-list-cell" *ngFor="let item of content3.children">
          <div class="qycloud-item2-list-cell-text">
            <img [src]="item.imgUrl">
            <div>
              {{item.email | translate}}
            </div>
          </div>
          <div class="qycloud-item2-list-cell-button">
            <a [href]="item.link">
              {{item.btn | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div class="qycloud-item-title">
      公司地址
    </div>
    <ul class="qycloud-contact-map">
      <li>
        {{'app.contact-us.contactAddress' | translate}} 
      </li>
      <li>{{'app.contact-us.contactAddressPostCode' | translate}} 
      </li>
    </ul>
    <div class="qyclpud-contact-us-bottom" id="qyclpud-contact-us-bottom" [ngStyle]="{backgroundImage: isShowDefaultMap ? 'url(/assets/img/contact/contact-map.png)' : 'none'}"
    ></div>
  </div>
</div>

<app-suspension></app-suspension>

<app-footer></app-footer>