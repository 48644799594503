import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

// declare 引入外部变量时声明使用
declare var BMap: any;
declare var BMAP_ANIMATION_BOUNCE: any;
declare var BMAP_NORMAL_MAP: any;
declare var BMAP_HYBRID_MAP: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('联系我们 - Qycloud企业云科技');
    this.meta.addTag({ name: 'keywords', content: 'Qycloud,企业云科技,燕麦云,OATOS,燕麦企业云盘,百货基因,FILEDNA' });
    this.meta.addTag({ name: 'description', content: 'Qycloud企业云科技为客户提供多渠道的服务保障，您对我们的产品或解决方案的任何需求或使用中遇到任何问题，都可以与我们取得联系。Qycloud企业云科技服务热线：4000861108' });
   }

  public contactTitle: string = 'app.contact-us.title';
  public contactDesc: string = 'app.contact-us.desc';
  public contactImg:string = './assets/img/contact/contact-bg.png';

  public contactContent1: Array<any> = [
    {
      title: 'app.contact-us.content1-title',
      desc: 'app.contact-us.content1-desc',
      children: [
        {
          imgUrl: './assets/img/contact/oatos.jpg',
          title: 'app.contact-us.content1-childTitle1'
        },
        {
          imgUrl: './assets/img/contact/merchdna.jpg',
          title: 'app.contact-us.content1-childTitle2'
        },
        // {
        //   imgUrl: './assets/img/contact/merchdna.jpg',
        //   title: 'FILEDNA'
        // },
      ]
    }
  ]

  public contactContent2: Array<any> = [
    {
      imgUrl: './assets/img/contact/contact-img.png',
      title: 'app.contact-us.content2-title',
      desc: [
        'app.contact-us.InternationalLine',
        'app.contact-us.serviceHotline',
        'app.contact-us.customerService'
      ],
      btn: 'app.contact-us.btn',
      link: 'https://url.cn/X6IXArLL?_type=wpa&qidian=true'
    }
  ]

  public contactContent3: Array<any> = [
    {
      title: 'app.contact-us.content3-title',
      children: [
        {
          imgUrl: './assets/img/contact/contact-oatos.svg',
          email: 'cs@oatos.com',
          btn: 'app.contact-us.btnSend',
          link: 'mailto: cs@oatos.com'
        },
        {
          imgUrl: './assets/img/contact/contact-merch.svg',
          email: 'cs@merchdna.com',
          btn: 'app.contact-us.btnSend',
          link: 'mailto: cs@merchdna.com'
        },
        {
          imgUrl: './assets/img/contact/contact-fileDNA.svg',
          email: 'filedna@qycloud.com',
          btn: 'app.contact-us.btnSend',
          link: 'mailto: filedna@qycloud.com'
        },
      ]
    }
  ]

  public contactContent4: Array<any> = [
    {
      title: 'app.contact-us.content4-title',
      desc: 'app.contact-us.contactAddress'
    }

  ]

  public isShowDefaultMap;

  ngOnInit(): void {
    this.createMap();
    this._jumpPage.goTop();
  }

  createMap() {
    // 百度地图API功能
    var map = new BMap.Map("qyclpud-contact-us-bottom");    // 创建Map实例
    var point = new BMap.Point(113.948819,22.554205);
    if (point) {
      this.isShowDefaultMap = false;
    }
    map.centerAndZoom(point, 19);  // 初始化地图,设置中心点坐标和地图级别
    var marker = new BMap.Marker(point);  // 创建标注
    map.addOverlay(marker);               // 将标注添加到地图中
    marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画

    //添加地图类型控件
    map.addControl(new BMap.MapTypeControl(
      {
        mapTypes:[
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP
        ]
      })
    );
    map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
  };

}
