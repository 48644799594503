import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MetaModule } from 'ng2-meta';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './_shared/footer/footer.component';
import { HeaderNavComponent } from './_shared/header-nav/header-nav.component';
import { HeaderComponent } from './_shared/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { SuspensionComponent } from './_shared/suspension/suspension.component';
import { LaboratoryComponent } from './pages/laboratory/laboratory.component';
import { CompanyComponent } from './pages/company/company.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
/*翻译库*/
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

import { PrivacyComponent } from './pages/privacy/privacy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CareersComponent } from './pages/careers/careers.component';

import { HttpService } from './_shared/service/http.service';
import { SafeHtmlPipe } from './_shared/pipes/safe-html.pipe';

import { version } from 'package.json';

// 自定义加载方法
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${version}`);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderNavComponent,
    HeaderComponent,
    HomeComponent,
    SuspensionComponent,
    LaboratoryComponent,
    CompanyComponent,
    ContactUsComponent,
    PrivacyComponent,
    TermsComponent,
    CareersComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MetaModule.forRoot(),
  ],
  providers: [
    HttpService,
    SafeHtmlPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }