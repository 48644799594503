<div class="qycloud-home-header">
  <app-header-nav></app-header-nav>

  <div class="qycloud-home-header-wrapper">
    <div class="qycloud-home-header-title">
      <img src="./assets/img/home/home-bg-text.png" >
    </div>
  </div>
</div>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div class="qycloud-home-item" *ngFor="let content of homeContent">
      <div class="qycloud-home-item-desc" [innerHTML]="content.desc | translate">
      </div>
      <div class="qycloud-home-item-content">
        <div class="qycloud-home-item-list" *ngFor="let item of content.children">
          <a target="_blank" [href]="item.link">
            <div 
              class="qycloud-home-item-list-img2" 
              [ngStyle]="{
                backgroundImage:'url(' + item.imgUrl1 + ')'
              }"
            >
            <div>
              <img [src]="item.imgUrl2">

              <div class="animation-bar"></div>
            </div>
            </div>
          </a>
          <div class="qycloud-home-item-list-desc">
            {{item.desc | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-suspension></app-suspension>

<app-footer></app-footer>