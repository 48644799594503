<!-- <app-header
  [title]="careersTitle | translate"
  [desc]="careersDesc | translate"
  [imgSrc]="careersImg"
></app-header> -->

<!-- <div class="qycloud-careers-tab">
  <a 
    *ngFor="let tab of tabs"
    [ngClass] ="{'qycloud-careers-content-tab-active': targetTab === tab.id}"
    [href]="tab.id"
  >
    <span>
      {{tab.title}}
    </span>
  </a>
</div> -->
<app-header-nav></app-header-nav>
<div class="qycloud-company-header">
  
  <div class="qycloud-company-header-wrapper">
    <div class="qycloud-company-header-title">
      <!-- {{'app.company-intro.header-title' | translate}} -->
      <p>{{careersTitle | translate}}</p>
    </div>
    <div class="qycloud-company-header-desc">
      {{careersDesc | translate}}
    </div>
  </div>
</div>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div class="qycloud-careers-wrapper" *ngFor="let item of post" [id]="item.id">
      <div class="qycloud-item-title">
        {{item.title | translate}}
      </div>
      <div class="qycloud-careers-desc">
        {{item.desc | translate}}
      </div>
      <div class="qycloud-careers-list">
        <div *ngFor="let children of item.children">
          <ng-container *ngFor="let desc of children.desc" >
            <a (click)="desc.showDetail = !desc.showDetail">
              <div class="qycloud-careers-list-main">
                <div class="qycloud-careers-list-main-1">
                  <div>
                    <div class="qycloud-careers-title">
                      {{children.title | translate}}
                    </div>
                    <div class="qycloud-careers-require">
                      {{children.require | translate}}
                    </div>
                  </div>
    
                  <div>
                    <img *ngIf="!desc.showDetail" src="../../../assets/img/down.svg">
                    <img *ngIf="desc.showDetail"  src="../../../assets/img/up.svg">
                  </div>
                </div>
              
                <div  class="qycloud-careers-detail" *ngIf="desc.showDetail" [innerHTML]="desc.text | translate"></div>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  
  <div class="qycloud-all qycloud-company-all2" [id]="content2.id" *ngFor="let content2 of companyContent2">
    <div class="qycloud-wrapper">
      <div class="qycloud-company-item" >
        <div class="qycloud-item-title">
          {{content2.title | translate}}
        </div>
  
        <div class="qycloud-company-item-content2">
          <div class="qycloud-company-item-content2-list" *ngFor="let item of content2.children">
            <img [src]="item.imgUrl">
            <div class="qycloud-company-item-content2-list-title">
              {{item.title | translate}}
            </div>
            <div class="qycloud-company-item-content2-list-desc">
              {{item.desc | translate}}
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
</div>

<app-suspension></app-suspension>

<app-footer></app-footer>