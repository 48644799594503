import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
  constructor(
    private _http: HttpClient,
  ) {
  }

  /**
   * 协议政策
   */
  public getPactDetails(request: {
    type: string;
  }): Observable<any> {
    let url: string = '/operator/publicity';

    let options: any = {
      params: {
        type: request.type,
      }
    };

    return this._http.get(url, options);
  }

}
