<div class="qycloud-company-header">
  <app-header-nav></app-header-nav>
  
  <div class="qycloud-company-header-wrapper">
    <div class="qycloud-company-header-title">
      <!-- {{'app.company-intro.header-title' | translate}} -->
      <p>深圳企业云科技股份</p>
      <p>有限公司</p>
    </div>
    <div class="qycloud-company-header-desc">
      {{'app.company-intro.header-desc' | translate}}
    </div>
  </div>
</div>

<div class="qycloud-all qycloud-company-all1" [id]="content1.id" *ngFor="let content1 of companyContent1">
  <div class="qycloud-wrapper">
    <div class="qycloud-company-item">
      <div class="qycloud-item-title">
        {{content1.title | translate}}
      </div>
      <div class="qycloud-company-content1">
        <div class="qycloud-company-content1-text" *ngFor="let item of content1.children">
          <div class="qycloud-company-content1-text-title">
            {{item.title | translate}}
          </div>
          <div class="qycloud-company-content1-text-desc" [innerHTML]="item.desc | translate"></div>
        </div>
        <div class="qycloud-company-content1-img">
          <img [src]="content1.imgUrl">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="qycloud-all qycloud-company-all2" [id]="content2.id" *ngFor="let content2 of companyContent2">
  <div class="qycloud-wrapper">
    <div class="qycloud-company-item" >
      <div class="qycloud-item-title">
        {{content2.title | translate}}
      </div>

      <div class="qycloud-company-item-content2">
        <div class="qycloud-company-item-content2-list" *ngFor="let item of content2.children">
          <img [src]="item.imgUrl">
          <div class="qycloud-company-item-content2-list-title">
            {{item.title | translate}}
          </div>
          <div class="qycloud-company-item-content2-list-desc">
            {{item.desc | translate}}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="qycloud-all qycloud-company-all3" [id]="content3.id" *ngFor="let content3 of companyContent3">
  <div class="qycloud-wrapper">
    <div class="qycloud-company-item3">
      <div class="qycloud-company-item-left">
        <div class="qycloud-company-item-left-title">
          {{content3.title | translate}}
        </div>
        <ul>
          <li>
            {{content3.desc1 | translate}}
          </li>
          <li>
            {{content3.desc2 | translate}}
          </li>
        </ul>
      </div>

      <div class="qycloud-company-item-right">
        <img [src]="content3.imgUrl">
      </div>
    </div>
  </div>
</div>

<div class="qycloud-all qycloud-company-all4" id="companyDevolop">
  <div class="qycloud-wrapper" id="company-develop">
    <div class="qycloud-item-title">
      {{ 'app.company-intro.content5-title' | translate }}
    </div>
    <div class="qycloud-company-intro-content-bottom">
      <div class="qycloud-company-intro-content-text">
        <div class="qycloud-company-intro-content-text-item" *ngFor="let content4 of companyContent4" >
          <div class="qycloud-company-intro-content-text-title">
            {{content4.title | translate}}
          </div>
          <div class="qycloud-company-intro-content-text-time">
            {{content4.ctime | translate}}
          </div>
          <div class="qycloud-company-intro-content-text-img">
            <img [src]="content4.imgUrl">
          </div>
          <div class="qycloud-company-intro-content-text-desc">
            {{content4.desc | translate}}
          </div>
        </div>
      </div>

      <div class="qycloud-company-intro-content-center">
        <div class="qycloud-company-intro-content-center-top"></div>
        <div class="qycloud-company-intro-content-center-bottom"></div>
      </div>
    </div>
  </div>
</div>

<div class="qycloud-all qycloud-company-all5" id="companyHonor">
  <div class="qycloud-wrapper" id="company-honor">
    <div class="oat-company-intro-intro-patent">
      <div class="oat-company-intro-patent-content">
        <div class="qycloud-item-title">
          {{'app.company-intro.tab4' | translate}}
        </div>
        <!-- <img src="/assets/img/company/patent_01.png" alt=""> -->
      </div>
      <div class="oat-company-intro-patent-content">
        <div class="oat-company-intro-patent-subtitle">{{ 'app.company-intro.honor-content2-title' | translate }}</div>
        <img src="/assets/img/company/patent_02.png" alt="">
        <img src="/assets/img/company/patent_03.png" alt="">
      </div>
      <div class="oat-company-intro-patent-content">
        <div class="oat-company-intro-patent-subtitle">{{ 'app.company-intro.honor-content3-title' | translate }}</div>
        <img src="/assets/img/company/patent_05.png" alt="">
      </div>
      <div class="oat-company-intro-patent-content">
        <div class="oat-company-intro-patent-subtitle">{{ 'app.company-intro.honor-content4-title' | translate }}</div>
        <img src="/assets/img/company/patent_06.png" alt="">
      </div>
    </div>
  </div>
</div>

<app-suspension></app-suspension>

<app-footer></app-footer>