<div class="qycloud-footer">
  <div class="qycloud-footer-wrapper">
    <div class="qycloud-footer-top">
      <div class="qycloud-footer-top-link">
        <div *ngFor="let item of footerArr">
          <ul>
            <span>
              {{item.title | translate}}
            </span>
            <li *ngFor="let children of item.children">
              <a *ngIf="children.link" target="_blank" [href]="children.link">
                {{children.title | translate}}
              </a>
              <a *ngIf="children.route" [routerLink]="children.route">
                {{children.title | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="qycloud-footer-top-img">
        <img src="./assets/img/logo-color.svg">
      </div>
    </div>

    <div class="qycloud-footer-bottom">
      Copyright © 2020 {{'app.footer.copyRightName' | translate}}    
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
        {{'app.footer.icp' | translate}}
      </a>  
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006375">
        {{'app.footer.policeIcp' | translate}}
      </a>
      <div class="product-footer-change-lang">
        <div class="product-change-lang-item">
          <img src="./assets/img/lang.svg"><span>{{ currentLang === 'zh-CHT' ? '繁體中文' : '简体中文' }}</span>
          <ul>
            <li *ngFor="let Lang of lang" >
              <span (click)="useLanguage(Lang)">{{ Lang.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>





