import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  constructor(
    private _translate: TranslateService,
    private _cookeis: CookieService,
    private _jumpPage: JumpPageService,
  ) { }

  public footerArr: Array<{
    title: string,
    children: Array<any>
  }> = [
    {
      title: 'app.footer.parentTitle1',
      children: [
        {
          title: 'app.footer.childTile1',
          link: 'https://www.oatos.com/'
        },
        {
          title: 'app.footer.childTile2',
          link: 'https://www.merchdna.com/'
        },
        {
          title: 'FILEDNA',
          link: 'https://www.filedna.tech/'
        },
        {
          title: 'app.footer.childTitle3',
          route: '/laboratory'
        }
      ]
    },
    {
      title: 'app.footer.parentTitle2',
      children: [
        {
          title: 'app.footer.childTitle4',
          route: '/contact'
        },
        {
          title: 'app.footer.childTitle5',
          link: 'https://wpa1.qq.com/INLW5mvs?_type=wpa&qidian=true'
        },
        {
          title: 'app.footer.childTitle6',
          route: '/careers'
        }
      ]
    },
    {
      title: 'app.footer.parentTitle3',
      children: [
        {
          title: 'app.footer.childTitle8',
          route: '/terms'
        },
        {
          title: 'app.footer.childTitle7',
          route: '/privacy'
        }
      ]
    },
  ];

  // 目前语言
  public currentLang: string;

  // 语言
  public lang: Array<any> = [
     {
       signal: 'zh-CHS',
       name: '简体中文'
     },
     {
       signal: 'zh-CHT',
       name: '繁體中文'
     }
   ];

  ngOnInit(): void {
  }

  public useLanguage(language: any) {
    this.currentLang = language.signal;
    this._translate.use(language.signal);
    this._cookeis.set('oatosLang', language.signal);
    this._jumpPage.goTop();
  }

}
