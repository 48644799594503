import { Component, OnInit } from '@angular/core';
import { title } from 'process';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) { 
    this.titleService.setTitle('Qycloud企业云科技');
    this.meta.addTag({ name: 'keywords', content: 'Qycloud,企业云科技,燕麦云,OATOS,燕麦企业云盘,百货基因,FILEDNA' });
    this.meta.addTag({ name: 'description', content: '企业云科技（深圳企业云科技股份有限公司）2013年成立于中国深圳，国家级高新技术企业，中国数据资产安全管理与智慧应用技术的领导者。我们以领先的研发创新能力和丰富行业经验立足中国，辐射全球，以核心技术持续为市场及社会创造价值。旗下业务包括：燕麦云智能企业云盘、百货基因、FILEDNA、明寻实验室。Qycloud企业云科技服务热线：4000861108。' });
  }

  public homeTitle: string = 'app.home.homeTitle';
  public homeImg: string = './assets/img/home/home-bg.png';

  public homeContent: Array<any> = [
    {
      desc: 'app.home.content-desc',
      children: [
        {
          imgUrl1: './assets/img/home/home-content1-1.png',
          imgUrl2: './assets/img/home/home-content1-2.svg',
          desc: 'app.home.content-childDesc1',
          link: 'https://www.oatos.com/'
        },
        {
          imgUrl1: './assets/img/home/home-content2-1.png',
          imgUrl2: './assets/img/home/home-content2-2.svg',
          desc: 'app.home.content-childDesc2',
          link: 'https://www.merchdna.com/'
        },
        {
          imgUrl1: './assets/img/home/home-content3-1.png',
          imgUrl2: './assets/img/home/home-content3-2.svg',
          desc: 'app.home.content-childDesc3',
          link: 'https://www.filedna.tech/'
        }
      ]
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
