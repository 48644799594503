import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.less']
})
export class LaboratoryComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('明寻实验室 - Qycloud企业云科技');
    this.meta.addTag({ name: 'keywords', content: '明寻实验室,Qycloud,企业云科技,燕麦云,OATOS,燕麦企业云盘,百货基因,FILEDNA' });
    this.meta.addTag({ name: 'description', content: '明寻实验室是是深圳企业云科技股份有限公司旗下的前沿技术研究中心，专注计算机视觉、区块链、信息安全、机器学习、新基建等方向的前沿技术探索与产业转化。Qycloud企业云科技服务热线：4000861108。' });
   }

  public laboratoryContent: Array<any> = [
    {
      title: 'app.laboratory.content-title',
      children: [
        {
          imgUrl: './assets/img/laboratory/laboratory-1.png',
          title: 'app.laboratory.content-childTitle1',
          desc: 'app.laboratory.content-childDesc1'
        },
        {
          imgUrl: './assets/img/laboratory/laboratory-2.png',
          title: 'app.laboratory.content-childTitle2',
          desc: 'app.laboratory.content-childDesc2'
        },
        {
          imgUrl: './assets/img/laboratory/laboratory-3.png',
          title: 'app.laboratory.content-childTitle3',
          desc: 'app.laboratory.content-childDesc3'
        },
        {
          imgUrl: './assets/img/laboratory/laboratory-4.png',
          title: 'app.laboratory.content-childTitle4',
          desc: 'app.laboratory.content-childDesc4'
        },
        {
          imgUrl: './assets/img/laboratory/laboratory-5.png',
          title: 'app.laboratory.content-childTitle5',
          desc: 'app.laboratory.content-childDesc5'
        },
      ]
    }
  ]

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
