import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';
import { HttpService } from 'src/app/_shared/service/http.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.less']
})
export class TermsComponent implements OnInit {

  public title: string = '';
  public content: string = '';

  constructor(
    private _jumpPage: JumpPageService,
    private _http: HttpService
  ) { }

  ngOnInit(): void {
    this._jumpPage.goTop();

    this._http.getPactDetails({
      type: 'user',
    }).subscribe({
      next: (result: any) => {
        this.title = result.data.title;
        this.content = result.data.content;
      },
      error: (errorCode: string) => {
        console.error(errorCode);
      }
    })
  }

}
