import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { title } from 'process';
import {JumpPageService} from 'src/app/_shared/service/jump-page.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.less']
})
export class CareersComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.titleService.setTitle('加入我们 - Qycloud企业云科技');
    this.meta.addTag({ name: 'keywords', content: 'Qycloud,企业云科技,燕麦云,OATOS,燕麦企业云盘,百货基因,FILEDNA' });
    this.meta.addTag({ name: 'description', content: 'Qycloud企业云科技秉持“正大光明、热爱自驱、开放进化”的企业文化，汇聚了一群热爱科学、热爱技术、热爱生活的小伙伴，欢迎您加入我们。Qycloud企业云科技服务热线：4000861108' });
   }

   public careersTitle: string = 'app.careers.careersTitle';
   public careersDesc: string = 'app.careers.careersDesc';
   public careersImg: string = './assets/img/career/career-bg.png';

   public tabs: Array<any> = [
     {
       title: 'app.careers.tabsTitle1',
       id: '#technology'
     },
     {
       title: 'app.careers.tabsTitle2',
       id: '#marketOperations'
     },
     {
       title: 'app.careers.tabsTitle3',
       id: '#sales'
     }
   ]

   public post: Array<any> = [
     {
      title: 'app.careers.postTitle1',
      id: 'technology',
      desc: 'app.careers.postDesc1',
      children: [
        {
          title: 'app.careers.postChildTitle1',
          require: 'app.careers.postChildRequire1',
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc1`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle2',
          require: 'app.careers.postChildRequire2',
          showDetail: false,
          desc: [
            {
              showDetail: false,
              text:`app.careers.postChildDesc2`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle3',
          require: 'app.careers.postChildRequire3',
          showDetail: false,
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc3`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle4',
          require: 'app.careers.postChildRequire4',
          showDetail: false,
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc4`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle5',
          require: 'app.careers.postChildRequire5',
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc5`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle6',
          require: 'app.careers.postChildRequire6',
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc6`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle7',
          require: 'app.careers.postChildRequire7',
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc7`
            }
          ]
        },
        {
          title: 'app.careers.postChildTitle8',
          require: 'app.careers.postChildRequire8',
          desc: [
            {
              showDetail: false,
              text:`app.careers.postChildDesc8`
            }
          ]
        }
      ]
     },
     {
      title: 'app.careers.postTitle2',
      id: 'marketOperations',
      desc: 'app.careers.postDesc2',
      children: [
        {
          title: 'app.careers.postChildTitle9',
          require: 'app.careers.postChildRequire9',
          desc: [
            {
              showDetail: false,
              text: `app.careers.postChildDesc9`
            }
          ]
        }
      ]
     },
     {
      title: 'app.careers.postTitle3',
      id: 'sales',
      desc: 'app.careers.postDesc3',
      children: [
        {
          title: 'app.careers.postChildTitle10',
          require: 'app.careers.postChildRequire10',
          desc: [
            {
              showDetail: false,
              text:`app.careers.postChildDesc10`
            }
          ]
        }
      ]
     }
   ]

   public companyContent2: Array<any> = [
    {
      title: 'app.company-intro.content2-title1',
      id: 'companyCulture',
      children: [
        {
          imgUrl: './assets/img/company/company-content2-1.png',
          title: 'app.company-intro.content2-childTitle1',
          desc: 'app.company-intro.content2-childDesc1'
        },
        {
          imgUrl: './assets/img/company/company-content2-2.png',
          title: 'app.company-intro.content2-childTitle2',
          desc: 'app.company-intro.content2-childDesc2'
        },
        {
          imgUrl: './assets/img/company/company-content2-3.png',
          title: 'app.company-intro.content2-childTitle3',
          desc: 'app.company-intro.content2-childDesc3'
        }
      ]
    }
  ]

   public targetTab: any;

  ngOnInit(): void {
    this._jumpPage.goTop();
  }

}
