import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { JumpPageService } from 'src/app/_shared/service/jump-page.service';

@Component({
  selector: 'app-suspension',
  templateUrl: './suspension.component.html',
  styleUrls: ['./suspension.component.less']
})
export class SuspensionComponent implements OnInit {

  constructor(
    public _jumpPage: JumpPageService,
  ) { }

  ngOnInit(): void {
  }

}
