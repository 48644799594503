<div class="qycloud-laboratory-header">
  <app-header-nav></app-header-nav>
  
  <div class="qycloud-laboratory-header-wrapper">
    <div class="qycloud-laboratory-header-title">
      {{'app.laboratory.header-title' | translate}}
    </div>

    <div class="qycloud-laboratory-header-desc">
      {{'app.laboratory.header-desc' | translate}}
    </div>
  </div>
</div>

<div class="qycloud-all">
  <div class="qycloud-wrapper">
    <div class="qycloud-laboratory-item" *ngFor="let content of laboratoryContent" >
      <div class="qycloud-item-title">
        {{content.title | translate}}
      </div>
      <div class="qycloud-laboratory-item-content">
        <div class="qycloud-laboratory-item-list" *ngFor="let item of content.children" >
          <div class="qycloud-laboratory-item-list-img">
            <img [src]="item.imgUrl">
          </div>
          <div class="qycloud-laboratory-item-list-content">
            <div class="qycloud-laboratory-item-list-title">
              {{item.title | translate}}
            </div>
            <div class="qycloud-laboratory-item-list-desc">
              {{item.desc | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-suspension></app-suspension>

<app-footer></app-footer>