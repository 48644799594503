<div>
  <div class="product-header-wrapper" id="product-header-wrapper">
    <app-header-nav></app-header-nav>
  </div>
  
  <!-- <div class="product-terms-wrapper">
    <div class="product-terms">
      <div class="product-terms-title">企业云服务条款</div>
      <div class="product-terms-date">最后更新日期：2020-3-18</div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">一、定义及解释</div>
        <ul>
          <li><strong>1.1 企业云公司：</strong>“深圳企业云科技股份有限公司”的简称，是企业云产品的开发者和运营商。</li>
          <li><strong>1.2 企业云：</strong>是由深圳企业云科技股份有限公司运营的云产品和服务。</li>
          <li><strong>1.3 甲方：</strong>指符合与同意企业云服务条款，使用企业云产品的企业或其它组织机构。</li>
          <li><strong>1.4 甲方用户：</strong>指甲方旗下或相关联的，经由甲方授权使用其企业云产品的单位或个人。</li>
          <li><strong>1.5 甲方管理员：</strong>指甲方专门指派的管理其企业云产品系统的单位或个人，企业云产品需甲方管理员进行配置后，甲方用户方能使用。</li>
          <li><strong>1.6 甲方账号：</strong>甲方用户需要凭借甲方管理员为其创建的“账号”才能登录使用企业云产品。</li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">二、服务说明</div>
        <ul>
          <li>
            <div><strong>2.1 </strong>企业云公司通过以下几种方式向企业客户提供服务：</div>
            <ul>
              <li>① 域名为*.oatos.com的网站：包括但不限于其下的所有网页应用程序；</li>
              <li>② 企业云公司已经发布的名为“燕麦云”、“OATOS”、“燕麦云智能企业云盘”、“燕麦云智能同步端”的桌面客户端程序（包括但不限于Windows，Mac-OSx操作系统）；</li>
              <li>③ (3)企业云公司已经发布的名为“燕麦云”、“OATOS”、“燕麦云智能企业云盘”的移动应用程序（包括但不限于iOS，Android系统）。</li>
            </ul>
          </li>
          <li><strong>2.2 </strong>企业云服务条款（以下统称“服务条款”）适用于企业云产品中提供的所有应用程序，但当甲方使用企业云产品某一特定服务时，如该服务另有单独的服务条款、指引或规则，应成为本条款的附加条款，甲方应与本条款一并遵守。
          </li>
          <li><strong>2.3 </strong>企业云产品以及功能的正常实现以甲方正确履行管理行为，正确完成系统设置等为前提条件。如甲方违反有关法律法规及本服务条款的规定，须自行承担完全的法律责任，并承担因此给企业云公司造成的损失的法律责任。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">三、服务体验</div>
        <ul>
          <li><strong>3.1 </strong>甲方可以申请免费试用并体验企业云产品，并约定试用时长、空间和帐号数量。
          </li>
          <li><strong>3.2 </strong>甲方可以在企业云产品试用时长到期日前购买企业云产品升级为正式用户；如甲方未在试用时长到期日前升级为正式用户，企业云公司将为甲方保留试用及已上传文件数据14个自然日，试用时长到期超过14个自然日时甲方仍然未升级为正式用户，视为甲方无意向购买企业云产品，企业云公司有权在不另行通知甲方的前提下删除甲方账户及已上传的文件数据。
          </li>
          <li><strong>3.3 </strong>甲方了解并同意在申请试用企业云产品时，其应提供真实有效的企业信息（如联系人、联系电话，电子邮箱地址等）。企业云公司有权对提供虚假信息用户的企业云产品停止试用并清除已上传数据。
          </li>
          <li><strong>3.4 </strong>甲方清楚并同意甲方及甲方用户在试用企业云产品前必须同时无条件接受本服务条款所有条款项的约束，并对违反国家法律规定及本服务条款所产生的一切后果承担法律责任。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">四、合同与费用</div>
        <ul>
          <li><strong>4.1 </strong>企业云为付费产品，甲方正式开始使用企业云产品前，需要与企业云公司签订《企业云订单合同》，合同以邮寄纸质合同的方式签署。甲方签署《企业云订单合同》的代表必须达到适用法律规定的具备法定行为能力的年龄。
          </li>
          <li><strong>4.2 </strong>甲方可通过企业云公司的服务电话4000861108或企业云官网www.oatos.com的在线客服与客服人员了解详细的服务费用，最终的产品及服务费用将以双方签订的《企业云订单合同》中所规定的价格为准。
          </li>
          <li><strong>4.3 </strong>企业云公司有权根据实际情况随时调整产品及服务费用标准，但该调整不会对甲方已经购买的服务期限内的产品或服务产生影响。
          </li>
          <li><strong>4.4 </strong>企业云产品及服务费用不包含甲方或甲方的用户访问互联网或移动网络而产生的相关费用。无论甲方付费与否，甲方必须自行配备连通互联网和使用电信增值业务所需的设备，自行担负个人上网或第三方（包括但不限于电信或移动通信提供商）收取的通讯费、信息费、流量费等有关费用。如涉及电信增值服务的，我们建议您与您的电信增值服务提供商确认相关的费用问题。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">五、甲方权利和义务</div>
        <ul>
          <li><strong>5.1 </strong>甲方不得利用企业云产品发送或传输任何含妨碍社会治安或非法、虚假、骚扰性、侮辱性、恐吓性、伤害性、破坏性、挑衅性、淫秽色情等内容的信息。
          </li>
          <li><strong>5.2 </strong>甲方须自行负责对其系统内部成员用户进行管理，如：增加（删减）账号、设置用户密码等管理行为。甲方须指派专门的系统管理员对其企业云产品账号进行维护管理，甲方管理员的行为由甲方负责，管理行为的后果以及责任由甲方承担。</li>
          <li>
            <strong>5.3 </strong>甲方及甲方用户应维持自身账号及密码的机密安全，甲方及甲方用户不得将其账号或密码转让或出借第三方使用。企业云公司无法对第三方非法或未经甲方授权使用其账号及密码的行为做出甄别，因此企业云公司不承担任何责任。在此，甲方同意并承诺做到：
            <ul>
              <li>① 当甲方及甲方用户的账号及密码遭到未获授权的使用，或者发生其他任何安全问题时，甲方立即有效通知企业云公司。</li>
              <li>② 甲方及甲方用户每次使用企业云产品完毕后，点击退出企业云产品。</li>
            </ul>
          </li>
          <li><strong>5.4 </strong>甲方已清楚明白，企业云产品仅可作为甲方内部使用，除非另有协议，甲方不得将其甲方账号进行转让、分销、或者买卖。</li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">六、企业云公司权利和义务</div>
        <ul>
          <li><strong>6.1 </strong>企业云公司有义务为甲方提供企业云产品的管理权限，便于甲方自行为甲方用户进行系统相关设置。</li>
          <li>
            <strong>6.2 </strong>企业云公司不得公开、编辑或对外披露甲方及甲方用户存储在企业云产品的任何数据。但下列情况除外：
            <ul>
              <li>① 事先得到甲方书面明确授权；</li>
              <li>② 根据中华人民共和国国家安全机构、公安部门的要求或根据相关的法律程序或维护社会公共利益的要求。</li>
            </ul>
          </li>
          <li><strong>6.3 </strong>如甲方或甲方用户违反本服务条款的约定或法律规定，企业云公司有权中断或终止向甲方提供企业云产品及服务并且剩余服务费用不进行退还。</li>
          <li><strong>6.4 </strong>企业云公司有权根据实际情况调整或更新本服务条款内容，企业云公司有义务通知甲方并在企业云产品官方网站作出明显公示。修改后的条款一旦公布即有效代替原有条款，新条款适用于所有企业云产品及服务用户。
          </li>
          <li><strong>6.5 </strong>如甲方及甲方用户违反国家法律法规或本服务条款，企业云公司有权立即停止向甲方提供服务而不需承担任何责任。</li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">七、知识产权、保密条款</div>
        <ul>
          <li><strong>7.1 </strong>企业云产品的所有权和基于企业云产品的所有知识产权（包括但不限于专利权、商标权、著作权、计算机软件著作权等）归企业云公司所有。企业云公司对企业云产品的所有权不包括甲方及甲方用户存放在企业云产品器中的数据。
          </li>
          <li><strong>7.2 </strong>企业云公司、企业云公司logo、企业云产品的logo等，以及其他企业云公司标志及产品、服务名称、均为企业云公司之商标（以下统称“企业云公司标识”）。未经企业云公司事先书面同意，甲方不得将企业云公司标识以任何方式展示或使用或作其他处理，也不得向他人表明甲方有权展示、使用、或其他有权处理企业云公司标识的行为。
          </li>
          <li><strong>7.3 </strong>甲方有义务保护企业云公司的知识产权不受第三方侵犯。甲方及甲方用户不得使用、复制企业云产品有关的软件、数据，不得允许第三方窃取企业云产品的任何数据与软件。数据不包括甲方及甲方用户在企业云产品中存放的数据。
          </li>
          <li><strong>7.4 </strong>甲方和企业云公司任何一方对在合作过程中所获知的对方及企业云公司未向社会公开的技术及商业秘密均有保密的义务，未经对方书面许可，任何一方不得将其泄露给第三方，否则应承担违约责任并赔偿损失。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">八、服务风险、免责声明</div>
        <ul>
          <li><strong>8.1 </strong>甲方已经清楚并明白，企业云产品仅依其当前所呈现的状况提供，企业云产品涉及到互联网及移动数据通讯等服务，可能会受到各个环节不稳定因素的影响。因此服务存在因洪水、地震、台风、火灾与其他天灾、战争等不可抗力及计算机病毒或黑客攻击、基于计算机系统和网络的各种犯罪行为、政府行为、系统出错或不稳定、用户所在位置、用户关机、网络不稳定、互联网络线路故障、骨干网通信线路故障原因等造成的服务中断或不能满足用户要求并因此给甲方带来损失的风险。甲方需承担上述风险，企业云公司不提供对企业云产品适销性、适用性、可靠性、及时性、安全性、完整性、无病毒及无错误的任何明示或默认保证，不承担因此而对甲方造成的任何直接、间接、附带、衍生或惩罚性的赔偿。
          </li>
          <li><strong>8.2 </strong>如企业云产品所发生的故障影响到甲方的正常使用，企业云公司承诺在第一时间内与相关单位配合，及时处理进行修复。企业云公司保留不经事前通知为维修保养、升级或其他目的暂停企业云产品任何部分的权利。在此期间发生的服务暂停现象，或由于网络通路的偶然阻塞造成服务应用故障，属正常情况，不可视为企业云公司违约。
          </li>
          <li><strong>8.3 </strong>甲方同意，甲方与甲方用户经由企业云产品上传、张贴、发送即时消息、电子邮件或其他方式传送的资讯、资料、文字、软件、音乐、音频、照片、图片、视频、信息、资源链接、用户登记资料或其他资料（以下统称“内容”），均由甲方与甲方用户承担完全的法律责任，企业云公司无须承担任何直接、间接、或连带责任。由于企业云公司无法控制经由企业云产品传送的内容，也无法对用户的使用行为进行全面监控，因此不保证甲方内容的合法性、正确性、完整性、真实性或品质。如甲方的内容存在侵犯第三人著作权的可能，企业云公司将遵循国家的法律法规进行处理。
          </li>
          <li><strong>8.4 </strong>甲方已经清楚地了解并知悉，甲方用户如需使用企业云产品及服务必须接受本服务条款。在甲方部分或全部甲方用户不接受或不同意本服务条款而导致无法使用企业云产品及服务时，甲方仍同意向企业云公司支付服务费用。
          </li>
          <li><strong>8.5 </strong>甲方承诺因甲方、甲方系统管理员、甲方用户对企业云产品的错误操作而对甲方所造成的任何损失负完全责任。这些错误操作包括但不限于甲方系统管理员误删甲方用户所造成的数据丢失、甲方用户错误地删除甲方存储在企业云产品中的数据等。
          </li>
          <li><strong>8.6 </strong>甲方及甲方用户已经清楚知悉，其通过企业云产品与商家或个人进行任何形式的通讯或商业往来、或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其他任何相关条款、条件、保证或证明，完全为甲方及甲方成员与商家及其他个人之间的行为。除有关法律有明文规定要求企业云公司承担责任以外，甲方因上述任何交易或上述商家或个人而遭受的任何性质的损失或损害，企业云公司均不予负责。
          </li>
          <li><strong>8.7 </strong>甲方在注册成功后180天内未登录使用燕麦公有云服务且未处于燕麦公有云增值服务时限内，企业云公司有权视具体情况注销甲方企业账户并清空甲方帐号内的数据。甲方明确知悉且自行承担账户被注销和数据被清空后所引发的一切后果，企业云公司不向甲方提供任何恢复数据服务。
          </li>
          <li><strong>8.8 </strong>在合同约定的服务到期时间期满前，企业云公司将通知甲方合同续约事宜。服务到期时，如甲方未续约合同，企业云公司有权终止为甲方提供企业云产品及相关服务，在此期间甲方可联系企业云公司迁移已存储在企业云产品中的数据；在合同期满30天后甲方仍未续约合同的，视为甲方已无意向继续使用企业云产品及服务，企业云公司有权在不另行通知甲方的前提下注销甲方企业云产品账户及清空甲方已存储在企业云产品中的数据。甲方明确知悉且自行承担企业云产品账户被注销和数据被清空后所引发的一切后果，企业云公司不向甲方提供任何恢复数据服务。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">九、信息收集、隐私保护</div>
        <ul>
          <li>
            <strong>9.1 </strong>企业云公司非常重视用户信息保护，根据国家法律法规及相关标准要求，企业云公司将严格按照《企业云隐私政策》进行用户信息的收集、保护与规范，详情请参阅《企业云隐私政策》。
          </li>
          <li>
            <strong>9.2 </strong>如果甲方及甲方用户对企业云隐私政策有疑问，或者对个人信息的处理存在担心和投诉，举报可能存在违反隐私保护的行为，可以发送电子邮件到privacy@qycloud.com与企业云公司联系。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">十、违约责任</div>
        <ul>
          <li><strong>10.1 </strong>甲方使用企业云产品及服务前已清楚了解并同意本服务条款，并对违反国家法律规定及本服务条款所产生的一切后果承担法律责任。</li>
          <li>
            <strong>10.2 </strong>因企业云公司过失而造成甲方在使用企业云产品及服务过程中遭受损失的，甲方有权以书面形式通知企业云公司终止其企业云产品账户。书面材料可邮寄至以下地址：深圳市南山区粤海街道滨海社区白石路3939号怡化金融科技大厦21层，深圳企业云科技股份有限公司（收）。经企业云公司查证如甲方之情况属实，企业云公司同意终止其企业云产品账户并在10个工作日内退还甲方服务终止日期至服务到期日期间剩余服务费用。
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">十一、服务变更、中断或终止及服务条款修改</div>
        <ul>
          <li><strong>11.1 </strong>企业云产品的所有权和运作权、一切解释权归企业云公司所有。企业云公司有权随时根据国家有关法律、法规的变化、行业的发展及公司经营状况和经营策略的调整等情况修改本服务条款。
          </li>
          <li><strong>11.2 </strong>企业云公司修改企业云产品或本服务条款时，应根据本服务条款6.4项执行。甲方有义务对最新的服务条款进行仔细阅读并确认，当发生有关争议时，以最新的服务条款为准。
          </li>
          <li><strong>11.3 </strong>企业云公司保留对企业云产品中所有应用程序的价格调整权及产品试用期的调整权。</li>
          <li>
            <strong>11.4 </strong>如发生下列任何一种情况，企业云公司有权随时中断或终止向甲方提供服务或禁用某些甲方用户账号而无须通知甲方：
            <ul>
              <li>① 甲方违反本服务条款的规定；</li>
              <li>② 按照国家主管部门的要求；</li>
              <li>③ 其他企业云公司认为是符合整体服务需求的特殊情形。</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">十二、法律及争议解决</div>
        <ul>
          <li><strong>12.1 </strong>本服务条款要与国家相关法律、法规一致，如发生与相关法律、法规条款有相抵触的内容，抵触部分以法律、法规条款为准。</li>
          <li><strong>12.2 </strong>如双方发生任何争议但又不能友好协商的，任何一方均可向企业云公司住所地深圳市有管辖权的人民法院提起诉讼。</li>
        </ul>
      </div>
      <div class="product-terms-detail">
        <div class="product-terms-subtitle">十三、其他</div>
        <ul>
          <li><strong>13.1 </strong>如本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效且具有约束力，并且努力使该规定反映之意向具备效力。</li>
          <li><strong>13.2 </strong>本服务条款构成甲方与企业云公司之全部协议，规范您对本服务之使用，并取代您先前与企业云公司达成的全部协议。但在甲方使用相关服务、或使用第三方提供的内容或软件时，亦应遵从所适用之附加条款及权利。
          </li>
          <li><strong>13.3 </strong>企业云公司保留本服务条款之所有解释权。</li>
        </ul>
      </div>
    </div>
  </div> -->
  <div class="product-terms-wrapper">
    <div class="product-terms">
      <div class="product-terms-title" [innerHtml]="title | safeHtml"></div>
      <div class="product-terms-detail" [innerHtml]="content | safeHtml"></div>
    </div>
  </div>
  <app-suspension></app-suspension>

  <app-footer></app-footer>
</div>