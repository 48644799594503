import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JumpPageService {

  constructor() { }

  // 跳转页面ID
  public goAnchor(selector) {
    let anchor = document.documentElement.querySelector(selector);
    let offsetTop = anchor.offsetTop - 100;
    document.documentElement.scrollTop = offsetTop;
    document.body.scrollTop = offsetTop;
  }

  // 返回顶部
  public goTop() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }
}
