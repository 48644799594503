import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    private cookie: CookieService,
  ) {
    let cookieExists: boolean = cookie.check('oatosLang');
    if (cookieExists) {
        let lang: string = cookie.get('oatosLang');
        translate.use(lang)
    } else {
        translate.setDefaultLang('zh-CHS');
    }
  }

  title = 'qycloud-official-website';
}
