import { Component, OnInit } from '@angular/core';
import { title } from 'process';
import {JumpPageService} from 'src/app/_shared/service/jump-page.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.less']
})
export class CompanyComponent implements OnInit {

  constructor(
    private _jumpPage: JumpPageService,
    private _router: Router,
    private meta: Meta,
    private titleService: Title
  ) { 
    this.titleService.setTitle('公司介绍 - Qycloud企业云科技');
    this.meta.addTag({ name: 'keywords', content: 'Qycloud,企业云科技,燕麦云,OATOS,燕麦企业云盘,百货基因,FILEDNA' });
    this.meta.addTag({ name: 'description', content: '创造技术价值，实现科技普惠。Qycloud企业云科技让技术为人所用，创造正能量的社会价值；Qycloud企业云科技致力于推动未来数字生活的到来，助力产业升级与社会的可持续性发展。Qycloud企业云科技服务热线：4000861108。' });
  }

  public companyContent1: Array<any> = [
    {
      title: 'app.company-intro.content1-title',
      id: 'companyIntro',
      imgUrl: './assets/img/company/copany-content1-1.png',
      children: [
        {
          title: 'app.company-intro.content1-childTitle1',
          desc: 'app.company-intro.content1-childDesc1'
        }
      ]
    },
    {
      title: 'app.company-intro.content1-title2',
      id: 'companyMission',
      imgUrl: './assets/img/company/copany-content1-2.png',
      children: [
        {
          title: 'app.company-intro.content1-childTitle2',
          desc: 'app.company-intro.content1-childDesc2'
        }
      ]
    }
  ]

  public companyContent2: Array<any> = [
    {
      title: 'app.company-intro.content2-title1',
      id: 'companyCulture',
      children: [
        {
          imgUrl: './assets/img/company/company-content2-1.png',
          title: 'app.company-intro.content2-childTitle1',
          desc: 'app.company-intro.content2-childDesc1'
        },
        {
          imgUrl: './assets/img/company/company-content2-2.png',
          title: 'app.company-intro.content2-childTitle2',
          desc: 'app.company-intro.content2-childDesc2'
        },
        {
          imgUrl: './assets/img/company/company-content2-3.png',
          title: 'app.company-intro.content2-childTitle3',
          desc: 'app.company-intro.content2-childDesc3'
        }
      ]
    }
  ]

  public companyContent3: Array<any> = [
    {
      title: 'app.company-intro.content3-title1',
      id: 'companyFounder',
      desc1: 'app.company-intro.content4-desc1',
      desc2: 'app.company-intro.content4-desc2',
      imgUrl: './assets/img/company/company-content3.png'
    }
   ]

   public companyContent4: Array<any> = [
     {
       title: 'app.company-intro.content5-subtitle8',
       ctime: '2020年',
       imgUrl: './assets/img/company/company-line-dot-right.png',
       desc: 'app.company-intro.content5-desc8'
     },
     {
       title: 'app.company-intro.content5-subtitle7',
       ctime: '2019年',
       imgUrl: './assets/img/company/company-line-dot-left.png',
       desc: 'app.company-intro.content5-desc7'
     },
     {
       title: 'app.company-intro.content5-subtitle6',
       ctime: '2018年',
       imgUrl: './assets/img/company/company-line-dot-right.png',
       desc: 'app.company-intro.content5-desc6'
     },
     {
       title: 'app.company-intro.content5-subtitle5',
       ctime: '2017年',
       imgUrl: './assets/img/company/company-line-dot-left.png',
       desc: 'app.company-intro.content5-desc5'
     },
     {
       title: 'app.company-intro.content5-subtitle4',
       ctime: '2016年',
       imgUrl: './assets/img/company/company-line-dot-right.png',
       desc: 'app.company-intro.content5-desc4'
     },
     {
       title: 'app.company-intro.content5-subtitle3',
       ctime: '2015年',
       imgUrl: './assets/img/company/company-line-dot-left.png',
       desc: 'app.company-intro.content5-desc3'
     },
     {
       title: 'app.company-intro.content5-subtitle2',
       ctime: '2014年',
       imgUrl: './assets/img/company/company-line-dot-right.png',
       desc: 'app.company-intro.content5-desc2'
     },
     {
       title: 'app.company-intro.content5-subtitle1',
       ctime: '2013年',
       imgUrl: './assets/img/company/company-line-dot-left.png',
       desc: 'app.company-intro.content5-desc1'
     }
   ]

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.jumpToAnchor();
  }

  // 跳转页面
  public jumpToAnchor() {
    if (this._router) {
      let urlSplit = this._router.routerState.snapshot.url.split('#');
      let id = urlSplit[1];
      console.log(id);
      if (id) {
        this._jumpPage.goAnchor( '#' + id);
      }
    } else {
      this._jumpPage.goTop();
    }
  }

}
