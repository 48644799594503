<div class="qycloud-main-widget">
  <ul>
    <li>
      <a class="qycloud-float-service"></a>
      <div class="qycloud-expand">
        <div class="qycloud-expand-content qycloud-expand-contact">
          <div>
            <span class="qycloud-expand-title">
              {{ 'app.suspension-parts.title1' | translate}}
            </span>
            <a class="qycloud-expand-qq" target="_blank" href="https://wpa1.qq.com/INLW5mvs?_type=wpa&qidian=true"></a>
            <span class="qycloud-expand-date">
              {{ 'app.suspension-parts.desc1' | translate}}
            </span>
          </div>
          <div>
            <span class="qycloud-expand-title">
              {{ 'app.suspension-parts.title2' | translate }}
            </span>
            <span class="qycloud-expand-phone">
              400-086-1108
            </span>
            <span class="qycloud-expand-date">
              {{ 'app.suspension-parts.desc2' | translate }}
            </span>
            <span class="qycloud-expand-phone">
              +86-755-23358866
            </span>
            <span class="qycloud-expand-date">
              {{ 'app.suspension-parts.desc1' | translate }}
            </span>
          </div>
        </div>
      </div>
    </li>
    <li>
      <a class="qycloud-float-wechat"></a>
      <div class="qycloud-expand">
        <div class="qycloud-expand-content qycloud-expand-wechat">
          <div class="qycloud-wechat-title">
            <span>
              {{ 'app.suspension-parts.title3' | translate }}
            </span>
            <br>
            <span>
              {{ 'app.suspension-parts.subtitle3' | translate }}
            </span>
          </div>
          <div class="qycloud-wechat-content">
            <div>
              <img src="./assets/img/qrcode_public.jpg">
              <span>
                {{ 'app.suspension-parts.desc3' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li>
      <a class="qycloud-float-weibo" target="_blank" href="http://weibo.com/oatos"></a>
    </li>
    <li (click)="_jumpPage.goTop()">
      <a class="qycloud-float-to-top"></a>
    </li>
  </ul>
</div>
