<app-header-nav></app-header-nav>

<div class="qycloud-header-wrapper">
  <div class="qycloud-header">
    <div class="qycloud-header-background-wrapper">
      <div 
        class="qycloud-header-background-image" 
        [ngStyle]="{
          backgroundImage:'url(' + imgSrc + ')'
        }"
      ></div>
    </div>

    <div class="qycloud-header-brief">
      <div 
        *ngIf="title" 
        class="qycloud-header-title" 
        [innerHTML]="title"
      ></div>

      <div 
        *ngIf="desc" 
        class="qycloud-header-desc"
        [innerHTML]="desc"
      ></div>
    </div>
  </div>
</div>