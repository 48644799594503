import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// 首页
import { HomeComponent } from 'src/app/pages/home/home.component';
// 企业介绍
import { CompanyComponent } from 'src/app/pages/company/company.component';
// 明寻实验室
import { LaboratoryComponent } from 'src/app/pages/laboratory/laboratory.component';
// 联系我们
import { ContactUsComponent } from 'src/app/pages/contact-us/contact-us.component';
// 隐私条款
import { PrivacyComponent } from 'src/app/pages/privacy/privacy.component';
// 服务政策
import { TermsComponent } from 'src/app/pages/terms/terms.component';
// 加入我们
import { CareersComponent } from 'src/app/pages/careers/careers.component';

const routes: Routes = [
  // 首页
  {
    path: 'home',
    component: HomeComponent
  },
  // 明寻实验室
  {
    path: 'laboratory',
    component: LaboratoryComponent
  },
  // 企业介绍
  {
    path: 'about',
    component: CompanyComponent
  },
  // 联系我们
  {
    path: 'contact',
    component: ContactUsComponent
  },
  // 隐私条款
  {
    path : 'privacy',
    component: PrivacyComponent
  },
  // 服务政策
  {
    path: 'terms',
    component: TermsComponent
  },
  // 加入我们
  {
    path: 'careers',
    component: CareersComponent
  },
  // 首页
  {
    path: '', 
    redirectTo: 'home', 
    pathMatch: 'full'
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
